import React, { useContext }from 'react';
import { RenderedContext } from '~layouts/layout';
import BannerContent from './BannerContent';
import ProfileBannerContent from './ProfileBannerContent';
import GatsbyImage from 'gatsby-image';

const ImageBanner = () => {
    const bannerData = useContext(RenderedContext);
    const image = bannerData.heroImage;

    return (
        <div className="image-banner">
            <div className={`hero-banner-image ${bannerData.mobileImage ? 'alternate-images' : ''}`}>
                {(!image.mobileImage && image) &&
                    <GatsbyImage
                        key={image.name}
                        alt={image.description}
                        fluid={image.fluid}
                        style={{height: '100%'}}
                        imgStyle={{
                            objectFit: 'cover',
                            objectPosition: 'top right',
                        }}
                        className="primary-hero-image"
                    />
                }
                {bannerData.mobileImage &&
                    <GatsbyImage
                        key={bannerData.mobileImage.name}
                        alt={bannerData.mobileImage.description}
                        fluid={bannerData.mobileImage.fluid}
                        style={{height: '100%'}}
                        imgStyle={{
                            objectFit: 'cover',
                            objectPosition: `top ${bannerData.mobileImageAlignment}`,
                        }}
                        className="mobile-hero-image"
                    />
                }

                {image.mobileImage &&
                    <img className="static-image" src={image.mobileImage} srcSet={`${image.mobileImage} 768w, ${image.tabletImage} 1024w, ${image.desktopImage} 1600w`} />
                }
            </div>

            <div className="image-overlay"></div>

            <div className="container">
                {bannerData.fullName &&
                    <ProfileBannerContent />
                }

                {!bannerData.fullName &&
                    <BannerContent />
                }
            </div>
        </div>
    );
};

export default ImageBanner;
