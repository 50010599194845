import React from 'react';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header = () => (
    <>
        <MobileHeader />
        <DesktopHeader />
    </>
);

export default Header;
