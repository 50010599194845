import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Footer, { AboutBlurb, CallBlurb } from '~2-components/Footer/Footer';
import RichText from '~3-data-components/RichText';

const FooterKontent = () => {
    const footerData = useStaticQuery(graphql`
        query footer {
            allKontentItemFooter {
                nodes {
                    elements {
                        facebook_url {
                            value
                        }
                        left_column_heading {
                            value
                        }
                        left_column_rich_text {
                            value
                        }
                        linkedin_url {
                            value
                        }
                        right_column_heading {
                            value
                        }
                        right_column_rich_text {
                            value
                        }
                        twitter_url {
                            value
                        }
                    }
                }
            }
        }
    `);

    const footerContent = footerData.allKontentItemFooter.nodes[0].elements;

    const aboutHeading = footerContent.left_column_heading.value;
    const aboutBlurb = footerContent.left_column_rich_text;
    const callHeading = footerContent.right_column_heading.value;
    const callBlurb = footerContent.right_column_rich_text;

    const facebookUrl = footerContent.facebook_url.value;
    const twitterUrl = footerContent.twitter_url.value;
    const linkedInUrl = footerContent.linkedin_url.value;

    return (
        <Footer
            callHeading={callHeading}
            aboutHeading={aboutHeading}
            facebookUrl={facebookUrl}
            twitterUrl={twitterUrl}
            linkedInUrl={linkedInUrl}
        >
            <AboutBlurb>
                <RichText
                    content={aboutBlurb}
                />
            </AboutBlurb>
            <CallBlurb>
                <RichText
                    content={callBlurb}
                />
            </CallBlurb>
        </Footer>
    );
};

export default FooterKontent;
