import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { allKontentItemSiteSettings } = useStaticQuery(
    graphql`
        query {
            allKontentItemSiteSettings {
                nodes {
                    elements {
                        site_title {
                          value
                        }
                        site_description {
                          value
                        }
                        site_url {
                          value
                        }
                        default_share_image {
                            value {
                              url
                            }
                        }
                        twitter_site {
                          value
                        }
                    }
                }
            }
        }
    `
  );
  const siteData = allKontentItemSiteSettings.nodes[0].elements;

  return siteData;
};

export default useSiteMetadata;