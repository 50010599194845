import React, { useState, useRef, useEffect, useContext }from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { RenderedContext } from '~layouts/layout';
import CallUsCTA from './CallUsCTA';
import CompanyLogo from './CompanyLogo';
import TextBanner from './TextBanner';
import MainNav from './MainNav';
import UtilityNav from './UtilityNav';
import ImageBanner from './ImageBanner';
import IconInline from '~2-components/IconInline/IconInline';
import ArticleHeader from './ArticleHeader';

export const MobileSizeContext = React.createContext(null);

const MobileHeader = () => {
    const bannerContent = useContext(RenderedContext);

    const [setActive, setActiveState] = useState('');
    const [setOpenMenuSize, setOpenMenuSizeState] = useState('0px');
    const [isInverse, setIsInverse] = useState(bannerContent.hasImage);

    function toggleMobileMenu() {
        setActiveState(setActive === '' ? "is-active" : '');
        setOpenMenuSizeState(
            setActive === "open" ? "0px" : `${mobileHeaderRef.current.offsetHeight}px`
        );
    }

    useEffect(() => {
        setIsInverse(setActive === "is-active");
    }, [setActive]);

    useEffect(() => {
        setOpenMenuSizeState(`${mobileHeaderRef.current.offsetHeight}px`);
    });

    const mobileHeaderRef = useRef(null);

    return (
        <>
            <Helmet>
                <body className={`mobile-menu-${setActive}`} />
            </Helmet>

            <section className={`mobile-header ${bannerContent.hasImage ? `has-image-banner` : ''}`}>
                <div className="mobile-header-bar" ref={mobileHeaderRef}>
                    <CompanyLogo
                        hasInverse={isInverse}
                    />

                    <div className="mobile-flex-wrapper">
                        <CallUsCTA />

                        <div className="triggers">
                            <Link
                                to={`/search`}
                                className={'mobile-search-trigger'}
                            >
                                <IconInline icon="search" />
                            </Link>
                            <button className={`mobile-nav-trigger ${setActive}`} type="button" aria-label="Mobile Menu" aria-controls="primary-links" onClick={toggleMobileMenu}>
                                <span className="flex-wrapper">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                    <span className="sr-only">Menu</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {bannerContent.article &&
                    <ArticleHeader />
                }

                {!bannerContent.hasImage && !bannerContent.article &&
                    <TextBanner />
                }

                {bannerContent.hasImage &&
                    <MobileSizeContext.Provider value={setOpenMenuSize}>
                        <ImageBanner />
                    </MobileSizeContext.Provider>
                }

                <nav className={`mobile-nav ${setActive}`} role="navigation" style={{top: `${setOpenMenuSize}` }}>
                    <MainNav />
                    <UtilityNav />
                </nav>
            </section>
        </>
    );
};

MobileHeader.propTypes = {};

export default MobileHeader;
