import React, { useContext } from 'react';
import UtilityNav from './UtilityNav';
import CompanyLogo from './CompanyLogo';
import MainNav from './MainNav';
import CallUsCTA from './CallUsCTA';
import TextBanner from './TextBanner';
import { RenderedContext } from '~layouts/layout';
import ImageBanner from './ImageBanner';
import Breadcrumbs from './Breadcrumbs';
import ArticleHeader from './ArticleHeader';

const DesktopHeader = () => {
    const bannerContent = useContext(RenderedContext);

    return (
        <>
            <section className={`desktop-header ${bannerContent.hasImage ? `has-image-banner` : ''}`}>
                <UtilityNav />
                <div className="header-bar">
                    <div className="container">
                        <div
                            className="grid-wrapper"
                        >
                            <div />
                            <MainNav />
                            <div />
                        </div>
                    </div>
                </div>
                <div className="logo-bar">
                    <div className="container">
                        <div className="grid-wrapper">
                            <CompanyLogo />
                            <div />
                            <CallUsCTA />
                        </div>
                    </div>
                </div>

                <Breadcrumbs />

                {bannerContent.article &&
                    <ArticleHeader />
                }

                {!bannerContent.hasImage && !bannerContent.article &&
                    <TextBanner />
                }

                {bannerContent.hasImage &&
                    <ImageBanner />
                }
            </section>
        </>

    );
};

export default DesktopHeader;
