import React, { useContext } from 'react';
import { RenderedContext } from '~layouts/layout';
import IconInline from '~2-components/IconInline/IconInline';

const BannerContent = () => {
    const bannerContent = useContext(RenderedContext);
    return (
        <div className="profile-banner-content">
            <h1 className="name">{bannerContent.fullName}</h1>
            <p className="position">{bannerContent.position}</p>
            {(bannerContent.location && bannerContent.location.length > 0) &&
                <div className="location">
                    <IconInline icon="location" />
                    {
                        bannerContent.location.map(office => {
                            return (<><a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(office.address)}`} target="_blank" rel="noopener noreferrer">{office.name}</a><span className="location-seperator">,&nbsp;</span></>);
                        })
                    }
                </div>
            }

            <div className="cta-wrapper">
                {bannerContent.phone.txt &&
                    <a href={bannerContent.phone.url}><IconInline icon="phone" />{bannerContent.phone.txt}</a>
                }
                {bannerContent.email.txt &&
                    <a href={bannerContent.email.url}><IconInline icon="envelope" />{bannerContent.email.txt}</a>
                }
            </div>
        </div>
    );
};

export default BannerContent;

