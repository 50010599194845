import React, { useMemo, useState } from 'react';
import PropTypes from "prop-types";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Icon from '~2-components/IconInline/IconInline';

const NavigationItem = ({ id, child, level }) => {
    // data comes from CMS
    const data = useStaticQuery(graphql`
        query allNavItems {
            allKontentItemNavigation {
                nodes {
                    elements {
                        caption {
                            value
                        }
                        drop_down_intro {
                            value
                        }
                        link_to_item {
                            value {
                                ...on kontent_item_general_content {
                                    elements {
                                        page_slug {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                        link_to_url {
                            value
                        }
                        caption {
                            value
                        }
                        open_in_new_window {
                            value {
                                codename
                            }
                        }
                        children {
                            value {
                                system {
                                    id
                                }
                            }
                        }
                    }
                    system {
                        name
                        id
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        data.allKontentItemNavigation.nodes.find((n) => id === n.system.id)
    ), [ data, id]);
    const name = match.system.name;
    const caption = match.elements.caption.value;
    const dropDownIntro = match.elements.drop_down_intro.value;
    const linkUrl = match.elements.link_to_url.value;
    const linkedItemUrl = match.elements.link_to_item.value.length > 0 ? match.elements.link_to_item.value[0].elements.page_slug.value : '';
    const subNavItems = match.elements.children.value.length > 0 ? match.elements.children.value : [];
    const url = linkedItemUrl ? linkedItemUrl : linkUrl;
    const title = caption.length > 0 ? caption : name;
    const [isActive, setIsActive] = useState(false);
    const useAnchor = url === 'contact-us' ? true : false;

    const itemClicked = () => {
        setIsActive(true);
    }

    const backClicked = () => {
        setIsActive(false);
    }

    return (
        <>
            {level && subNavItems.length > 0 &&
                <div className={`mobile-menu-back-container level-${level}${isActive ? ' mobile-is-active': ''}`}>
                    <button className="btn-skin-3 mobile-menu-back-button" onClick={() => backClicked()}>
                        <Icon icon="chevron-left"/>
                        Back
                    </button>
                </div>
            }
            <li key={title} className={`${subNavItems.length > 0 ? 'has-sub ': ''}mobile-menu-item mobile-menu-level-${level} ${isActive ? ' mobile-is-active': ''}`} onClick={() => itemClicked()}>
                {(subNavItems.length <= 0 && !useAnchor) &&
                    <Link to={`/${url}`} className={caption.length > 0 ? `preserve-line-breaks` : ``} activeClassName="is-active">{title}</Link>
                }
                {(subNavItems.length <= 0 && useAnchor) &&
                    <a href={`/${url}`} className={caption.length > 0 ? `preserve-line-breaks` : ``} activeClassName="is-active">{title}</a>
                }
                {subNavItems.length > 0 &&
                <>
                    <Link to={`/${url}`} className="display-desktop-only" activeClassName="is-active">{title}</Link>
                    <Link to={`/${url}`} className="display-mobile-only" activeClassName="is-active" onClick={e => { e.preventDefault(); itemClicked(); }}>{title}</Link>
                    <div className={`subnav${!child ? ' parent-nav': ''}`}>
                        <div className="subnav-container">
                            {dropDownIntro &&
                            <div className="subnav-intro" dangerouslySetInnerHTML={{ __html: dropDownIntro }} />
                            }
                            <ul>
                                <li key={title} className={`mobile-menu-item mobile-menu-level-${level + 1} display-mobile-only`}>
                                    <Link to={`/${url}`} className={caption.length > 0 ? `preserve-line-breaks` : ``} activeClassName="is-active">{title}</Link>
                                </li>
                                {subNavItems.map(item => {
                                    return(<NavigationItem key={item.system.id} id={item.system.id} child={true} level={level + 1}/>);
                                })}
                            </ul>
                        </div>
                    </div>
                </>
                }
                
            </li>
        </>
    );
};

NavigationItem.propTypes = {
    id: PropTypes.string.isRequired,
    child: PropTypes.bool,
    level: PropTypes.number
};

export default NavigationItem;
