import React, { useContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RenderedContext } from '~layouts/layout';

const MediaQueryContact = () => {
    const bannerContent = useContext(RenderedContext);

    const data = useStaticQuery(graphql`
        query findMediaQueryContact {
            allKontentItemProfile {
                nodes {
                    elements {
                        first_name {
                            value
                        }
                        last_name {
                            value
                        }
                        email {
                            value
                        }
                        position {
                            value
                        }
                    }
                    system {
                        id
                    }
                    id
                }
            }
        }
    `);

    const profileID = bannerContent.mediaQuery;

    const match = useMemo(() => (
        data.allKontentItemProfile.nodes.find((n) => profileID === n.system.id)
    ), [ data, profileID]);

    const firstName = match.elements.first_name.value;
    const lastName = match.elements.last_name.value;
    const name = `${firstName} ${lastName}`;
    const email = match.elements.email.value;
    const position = match.elements.position.value;

    return (
        <div className="media-query-contact">
            <p className="title">For all media enquiries:</p>
            <p className="name">{name}</p>
            <p className="position">{position}</p>
            <a href={`mailto:${email}`} className="email">{email}</a>
        </div>
    );
};

export default MediaQueryContact;

