import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import IconIllustration from '~2-components/IconIllustration/IconIllustration';

const CompanyLogo = ({ hasInverse }) => (
    <div className="company-logo">
        <Link to={`/`}>
            <IconIllustration icon={hasInverse ? 'redlichs-logo-inverse' : 'redlichs-logo'} />
        </Link>
    </div>
);

export default CompanyLogo;

CompanyLogo.propTypes = {
    hasInverse: PropTypes.bool,
};
