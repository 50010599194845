import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import RichText from '~3-data-components/RichText';
import Icon from '~2-components/IconInline/IconInline';
import formatPhoneNumber from '~utils/formatPhoneNumber';

const BannerContactInfo = () => {
    const data = useStaticQuery(graphql`
        query findBannerContactInfo {
            allKontentItemSiteSettings {
                nodes {
                    elements {
                        alternative_phone_number_prompt {
                            value
                        }
                        phone_number {
                            value
                        }
                    }
                }
            }
        }
    `);

    const content = data.allKontentItemSiteSettings.nodes[0].elements.alternative_phone_number_prompt;
    const displayNumber = data.allKontentItemSiteSettings.nodes[0].elements.phone_number.value;
    const formattedNumber = formatPhoneNumber(displayNumber);

    return (
        <div className="banner-contact-info">
            <RichText
                content={content}
            />

            <a href={`tel:${formattedNumber}`}>
                <Icon icon={`phone`} />
                <h3>{`Call ${displayNumber}`}</h3>
            </a>
        </div>
    );
};

export default BannerContactInfo;

