import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import EnquiryCTA from '~2-components/EnquiryCTA/EnquiryCTA';

export const AboutBlurb = ({ children }) => (<>{children}</>);
export const CallBlurb =({ children }) => (<>{children}</>);

const Footer = (props) => {
    const {
        callHeading,
        aboutHeading,
        facebookUrl,
        twitterUrl,
        linkedInUrl,
        children,
    } = props;

    const callBlurb = children.filter(child => child.type === CallBlurb);
    const aboutBlurb = children.filter(child => child.type === AboutBlurb);

    return (
        <footer>
            <div className="desktop-container">
                <div className="desktop-row">
                    <div className="call-adviceline">
                        <h2>{ callHeading }</h2>
                        <div className="blurb">
                            {callBlurb}
                        </div>
                        <div className="call-ctas">
                            <EnquiryCTA />
                        </div>
                    </div>
                    <div className="about-adviceline">
                        <h5>{ aboutHeading }</h5>
                        <div className="blurb">
                            {aboutBlurb}
                        </div>
                        <div className="social-icons">
                            {facebookUrl &&
                                <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                                    <Icon icon={'facebook'} />
                                </a>
                            }
                            {twitterUrl &&
                                <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                                    <Icon icon={'twitter'} />
                                </a>
                            }
                            {linkedInUrl &&
                                <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                                    <Icon icon={'linkedin'} />
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

Footer.propTypes = {
    callHeading: PropTypes.string.isRequired,
    aboutHeading: PropTypes.string.isRequired,
    facebookUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
    linkedInUrl: PropTypes.string,
    children: PropTypes.node,
};

AboutBlurb.propTypes = {
    children: PropTypes.node,
};

CallBlurb.propTypes = {
    children: PropTypes.node,
};
