import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Icon from '~2-components/IconInline/IconInline';
import formatPhoneNumber from '~utils/formatPhoneNumber';

const CallUsCTA = () => {
    const data = useStaticQuery(graphql`
        query callUsCTA {
            allKontentItemSiteSettings {
                nodes {
                    elements {
                        phone_number_prompt {
                            value
                        }
                        phone_number {
                            value
                        }
                    }
                }
            }
        }
    `);

    const siteSettings = data.allKontentItemSiteSettings.nodes[0].elements;

    const text = siteSettings.phone_number_prompt.value;
    const number = siteSettings.phone_number.value;
    const formattedNumber = formatPhoneNumber(number);

    return (
        <a href={`tel:${formattedNumber}`} className="call-us-cta">
            <Icon icon={`phone`} />
            <div className="call-us-text">
                <p>{text}</p>
                <h5>{`Call ${number}`}</h5>
            </div>
        </a>
    );
};

export default CallUsCTA;

