import React, { useContext } from 'react';
import BannerContent from './BannerContent';
import ProfileBannerContent from './ProfileBannerContent';
import MediaQueryContact from './MediaQueryContact';
import { RenderedContext } from '~layouts/layout';

const TextBanner = () => {
    const bannerContent = useContext(RenderedContext);

    return (
        <div className={`text-banner${bannerContent.fullName ? ' profile-banner' : ''}`}>
            <div className="container">
                {bannerContent.fullName &&
                    <ProfileBannerContent />
                }

                {!bannerContent.fullName &&
                    <BannerContent />
                }

                <div className="sidebar">
                    {bannerContent.mediaQuery &&
                        <MediaQueryContact />
                    }
                </div>
            </div>
        </div>
    );
};

export default TextBanner;

