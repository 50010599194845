import React from 'react';
import EnquiryCTA from '~2-components/EnquiryCTA/EnquiryCTA';

const StickyEnquiryCta = () => (
    <div className="sticky-enquiry-cta">
        <EnquiryCTA />
    </div>
);

export default StickyEnquiryCta;
