import React, { useEffect } from 'react';
import Icon from '~2-components/IconInline/IconInline';

let scrollHeight, backToTopButton, buttonHeight, centreOfButton;

// show BackToTop button on page scroll
const windowScroll = () => {
    scrollHeight = window.pageYOffset;
    const topOfFooter = document.querySelector('footer') ? document.querySelector('footer').getBoundingClientRect().top : null;

    if (scrollHeight > 300 ) {
        backToTopButton.classList.add('display');
    } else {
        backToTopButton.classList.remove('display');
    }

    // anchor button to top of footer
    if (topOfFooter && centreOfButton >= topOfFooter) {
        const buttonFooterOffset = centreOfButton - topOfFooter;
        backToTopButton.style.bottom = `${buttonFooterOffset + 16}px`;
    } else {
        backToTopButton.style.bottom = '16px';
    }
};

// take user back to top of page
const backToTop = (e) => {
    e.preventDefault();
    scrollHeight = window.pageYOffset;

    setTimeout(function() {
        document.documentElement.scrollTop = scrollHeight - 10;
        if (scrollHeight === 0) return;

        backToTop(e);
    }, 5);

};

const BackToTop = () => {
    useEffect(() => {
        backToTopButton = document.querySelector('#back-to-top');
        buttonHeight = backToTopButton.offsetHeight;
        centreOfButton = backToTopButton.getBoundingClientRect().top + (buttonHeight / 2);
        window.onscroll = windowScroll;
    });

    return (
        <a href="#" onClick={backToTop} className="back-to-top" id="back-to-top">
            <Icon
                icon={'arrow-up'}
            />
        </a>
    );
};

export default BackToTop;