import React, { useContext } from 'react';
import { RenderedContext } from '~layouts/layout';
import BannerContactInfo from './BannerContactInfo';
import RichText from '~3-data-components/RichText';
import parseHTML from 'html-react-parser';
import { RichTextValidation } from '~2-components/Utils/RichTextValidation';

const BannerContent = () => {
    const bannerContent = useContext(RenderedContext);
    const staticIntro = bannerContent.staticIntroduction ? parseHTML(bannerContent.staticIntroduction) : null;
    return (
        <div className="banner-content">
            <h1 className="title">{ bannerContent.title }</h1>
            {RichTextValidation(bannerContent.introduction) &&
                <div className="introduction">
                    <RichText
                        content={bannerContent.introduction}
                    />
                </div>
            }
            {staticIntro &&
                <div className="introduction">
                    {staticIntro}
                </div>
            }
            {bannerContent.cta1 && bannerContent.cta1.txt &&
                <div className="cta-wrapper">
                    <a href={bannerContent.cta1.url}>{bannerContent.cta1.txt}</a>
                    {bannerContent.cta2.txt &&
                        <a href={bannerContent.cta2.url}>{bannerContent.cta2.txt}</a>
                    }
                </div>
            }

            {bannerContent.showContact &&
                <BannerContactInfo />
            }
        </div>
    );
};

export default BannerContent;

