import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import Icon from '~2-components/IconInline/IconInline';
import { RenderedContext } from '~layouts/layout';

const Breadcrumbs = () => {
    const bannerData = useContext(RenderedContext);
    const breadcrumbs = bannerData.breadcrumbs;

    return (
        <div className="breadcrumbs">
            <div className="container">
                {breadcrumbs.map(({title, pageSlug, isActive}, index) => {
                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && <span className="breadcrumb-separator"></span>}<a key={title} href={`/${pageSlug === '/' ? '' : pageSlug}`} className={`breadcrumb ${isActive ? 'is-active' : ''}`}>{index === 0 && <Icon icon="home" />}<span>{title}</span></a>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
