import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import IconInline from '~2-components/IconInline/IconInline';
import NavigationItem from './NavigationItem';

const UtilityMenu = () => {
    const data = useStaticQuery(graphql`
        query getUtilityNavItems {
            allKontentItemSiteSettings {
                nodes {
                    elements {
                        utility_navigation {
                            value {
                                ...on kontent_item_navigation {
                                    elements {
                                        children {
                                            value {
                                                system {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const utilityNavItems = data.allKontentItemSiteSettings.nodes[0].elements.utility_navigation.value[0].elements.children.value;

    return (
        <nav className="utility-nav">
            <div className="container">
                <ul>
                    {utilityNavItems.map(item => {
                        return <NavigationItem key={item.system.id} id={item.system.id} />;
                    })}
                    <li className="search-trigger">
                        <Link
                            to={`/search`}
                            className={'mobile-search-trigger'}
                                >
                            <IconInline icon="search" />
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default UtilityMenu;
