import React, { useContext } from 'react';
import { RenderedContext } from '~layouts/layout';
import Icon from '~2-components/IconInline/IconInline';


const ArticleHeader = () => {
    const bannerContent = useContext(RenderedContext);

    return (
        <div className="news-header">
            <div className="container">
                <div className="news-title">
                    <h1>{bannerContent.title}</h1>
                    <div className="metadata-container">
                        <div className="metadata">
                            <p>{bannerContent.date}</p>
                            <Icon icon={`indicator-on`}/>
                            {bannerContent.fullName &&
                            <p>by {bannerContent.fullName}</p>
                            }
                        </div>
                        <div className="social-share-container">
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${bannerContent.pagePath}`} target="__blank">
                                <Icon icon={`facebook`}/>
                            </a>
                            <a href={`https://twitter.com/intent/tweet?url=${bannerContent.pagePath}`} target="__blank">
                                <Icon icon={`twitter`}/>
                            </a>
                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${bannerContent.pagePath}`} target="__blank">
                                <Icon icon={`linkedin`}/>
                            </a>
                            <a href={`mailto:?&subject=${bannerContent.pagePath}&body=${bannerContent.pagePath}`}>
                                <Icon icon={`envelope`}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleHeader;

