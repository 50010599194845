import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FeatureCTA from '~2-components/FeatureCTA/FeatureCTA';
import GatsbyImage from 'gatsby-image';

const FeatureCTAKontent = () => {
    const data = useStaticQuery(graphql`
        query featureCTA {
            allKontentItemFooter {
                nodes {
                    elements {
                        feature_cta_heading {
                            value
                        }
                        feature_cta_hero_image {
                            value {
                                fluid(maxWidth: 584) {
                                    ...KontentAssetFluid_withWebp
                                }
                            }
                        }
                        feature_cta_link {
                            value {
                                ...on kontent_item_navigation {
                                    id
                                    elements {
                                        link_to_item {
                                            value {
                                                ...on kontent_item_general_content {
                                                    elements {
                                                        page_slug {
                                                            value
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_url {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                        feature_cta_text {
                            value
                        }
                        feature_cta_link_text {
                            value
                        }
                    }
                }
            }
        }
    `);

    const featureCTAData = data.allKontentItemFooter.nodes[0].elements;

    const heading = featureCTAData.feature_cta_heading.value;
    const text = featureCTAData.feature_cta_text.value;
    const heroImage = featureCTAData.feature_cta_hero_image.value[0];

    const linkUrl = featureCTAData.feature_cta_link.value[0].elements.link_to_url.value;
    const linkedItemUrl = featureCTAData.feature_cta_link.value[0].elements.link_to_item.value.length > 0 ? featureCTAData.feature_cta_link.value[0].elements.link_to_item.value[0].elements.page_slug.value : '';
    const btnTxt = featureCTAData.feature_cta_link_text.value;

    const url = linkedItemUrl ? linkedItemUrl : linkUrl;

    return (
        <FeatureCTA
            heading={heading}
            text={text}
            btnTxt={btnTxt}
            btnUrl={url}
            cmsImage={
                <GatsbyImage
                    key={heroImage.name}
                    alt={heroImage.description}
                    fluid={heroImage.fluid}
                />
            }
        />
    );
};

export default FeatureCTAKontent;
