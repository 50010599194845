import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Icon from '~2-components/IconInline/IconInline';
import formatPhoneNumber from '~utils/formatPhoneNumber';
import { Link } from 'gatsby';

const EnquiryCTA = () => {
    const enquiryCTAData = useStaticQuery(graphql`
        {
            allKontentItemSiteSettings {
                nodes {
                    elements {
                        phone_number {
                            value
                        }
                        make_an_enquiry_prompt {
                            value
                        }
                        make_an_enquiry_cta {
                            value {
                                ... on kontent_item_general_content {
                                    elements {
                                        page_slug {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const siteSettings = enquiryCTAData.allKontentItemSiteSettings.nodes[0].elements;
    const callFormattedNumber = siteSettings.phone_number.value;
    const callPhoneNumber = formatPhoneNumber(callFormattedNumber);
    const emailCTAText = siteSettings.make_an_enquiry_prompt.value;
    const emailCTAUrl = siteSettings.make_an_enquiry_cta.value[0].elements.page_slug.value;

    return (
        <>
            <a href={`tel:${callPhoneNumber}`} className={`call-cta`}>
                <Icon icon={'phone'} />
                {callFormattedNumber}
            </a>
            <a href={`/${emailCTAUrl}`} className={`contact-cta`}>
                <Icon icon={'envelope'} />
                {emailCTAText}
            </a>
        </>
    );
};

export default EnquiryCTA;

