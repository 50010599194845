import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from '~utils/requiredIf';
import { Link } from 'gatsby';

const FeatureCTA = (props) => {
    const {
        cmsImage,
        imgUrl,
        imgAlt,
        heading,
        text,
        btnUrl,
        btnTxt,
    } = props;

    return (
        <div className="feature-cta">
            <div className="container">
                <div className="desktop-row">
                    <div className="image-wrap">
                        {/* Handles Gatsby Images */}
                        {cmsImage &&
                            cmsImage
                        }
                        {imgUrl &&
                            <img src={imgUrl} alt={imgAlt} />
                        }
                    </div>
                    <div className="copy-wrap">
                        <h2>{heading}</h2>
                        <h4>{text}</h4>
                        <Link to={`/${btnUrl}`}>{btnTxt}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureCTA;

FeatureCTA.propTypes = {
    cmsImage: PropTypes.node,
    imgUrl: requiredIf(PropTypes.string, props => !props.cmsImage),
    imgAlt: requiredIf(PropTypes.string, props => props.imgUrl),
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    btnUrl: PropTypes.string.isRequired,
    btnTxt: PropTypes.string.isRequired,
};
