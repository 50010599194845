import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import FooterKontent from '~3-data-components/FooterKontent';
import '~styles/styles.scss';
import appleFavicon from '~assets/favicon/apple-touch-icon.png';
import favicon32 from '~assets/favicon/favicon-32x32.png';
import favicon16 from '~assets/favicon/favicon-16x16.png';
import FeatureCTAKontent from '~3-data-components/FeatureCTAKontent';
import StickyEnquiryCta from '~2-components/StickyEnquiryCta/StickyEnquiryCta';
import BackToTop from '~2-components/BackToTop/BackToTop';
import useSiteMetadata from '~3-data-components/useSiteMetaData';

export const RenderedContext = React.createContext();

const Layout = ({ hideFeatureCTA, children }) => {

    const { site_title, site_description } = useSiteMetadata();
    const title = site_title.value.length ? site_title.value : 'Site Title';
    const description = site_description.value.length ? site_description.value : 'Site Description';

    return (
        <>
            <Helmet
                title={title}
            >
                <meta name="description" content={description} />
                <link rel="apple-touch-icon" sizes="180x180" href={appleFavicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
                <link rel="manifest" href="/static/favicon/site.webmanifest" />
            </Helmet>

            {children}

            {!hideFeatureCTA &&
                <FeatureCTAKontent />
            }

            <FooterKontent />

            <StickyEnquiryCta />

            <BackToTop />
        </>
    );
};

Layout.propTypes = {
    hideFeatureCTA: PropTypes.bool,
    children: PropTypes.array,
};

export default Layout;
