import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavigationItem from './NavigationItem';

const MainNav = (props) => {
    const data = useStaticQuery(graphql`
    {
        allKontentItemSiteSettings {
            nodes {
                elements {
                    main_navigation {
                        value {
                            ...on kontent_item_navigation {
                                elements {
                                    children {
                                        value {
                                            system {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `);

    const mainNavItems = data.allKontentItemSiteSettings.nodes[0].elements.main_navigation.value[0].elements.children.value;
    const { isMobile } = props;

    return (
        <nav className="main-nav">
            <div className="mobile-container">
                <ul>
                    {mainNavItems.map(item => {
                        return <NavigationItem key={item.system.id} id={item.system.id} level={1}/>;
                    })}
                </ul>
            </div>
        </nav>
    );
};

export default MainNav;
